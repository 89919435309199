import { Route, Switch } from "react-router-dom";
import { PrivateRoute, LagacyRoute, PublicRoute } from "./components";
import { AuthContextProvider } from "./contexts/auth-context";
import "./core-ui/core-styles/css/global.module.css";
import "./core-ui/core-styles/scss/material-kit-react.scss";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

import {
    Dashboard,
    SignIn,
    SignUp,
    UpdateAccountInfo,
    ForgotPassword,
    ResetPassword,
    RoutePlanTableManagement,
    // SelectOutletBranches,
    ExclusivityContracts,
    UpdateExclusivityContract,
    Subscriptions,
    Gallery,
    VisitsPool,
    DailyMerchandisingReport,
    MerchandiserAttendanceReport,
    ExpiryAndDamageReport,
    MobileDashboard,
    InsourceProductMerchandisingProjects,
    AddInsourceProductMerchandisingProject,
    Brands,
    Products,
    AddProduct,
    UpdateProduct,
    PromoterProjects,
    AddPromotingProject,
    // ManagePromotingProject,
    CompetitorBrands,
    CompetitorProducts,
    AddCompetitorProduct,
    UpdateCompetitorProduct,
    PromotionPrices,
    AddPromotionPrices,
    UpdatePromotionPrices,
    LinkOutlets,
    LinkOutletBranches,
    LinkedProductsWithOutlets,
    LinkedProductsWithOutletBranches,
    SelectOutletBranches,
    NearExpiryReport,
    OutOfStockReport,
    Users,
    VerifyEmail,
} from "./pages";
import {
    AccountInfo,
    AddBranch,
    AddExtraDisplayContract,
    AddInsourceProject,
    AddEmployee,
    AddOutsourceProject,
    // AddProduct,
    BranchesList,
    ExtraDisplayContracts,
    Guide,
    InsourceProjects,
    InsourceVisitDetails,
    InsourceVisitReport,
    InsourceVisitsManagement,
    // LinkedOutlets,
    // LinkOutletWithProducts,
    ManageInsourceProject,
    ManageOutsourceProject,
    EmployeesList,
    OutsourceProjects,
    OutsourceVisitDetails,
    OutsourceVisitReport,
    OutsourceVisitsManagement,
    // ProductsList,
    RouteVisitsManagement,
    // SelectOutlets,
    UpdateBranch,
    UpdateExtraDisplayContract,
    UpdateEmployee,
    // UpdateProduct,
} from "./pages-lagacy";
import PATHS from "./constants/paths";
import { GeolocationContextProvider } from "./contexts/geolocation-context";
import { CompatRoute } from "react-router-dom-v5-compat";
// import ProductsImporter from "./pages/products-importer";

/* 
! TODO: need to remove all class based componoents and use functional compononts in order to be able to use useNavigate
ref: https://github.com/remix-run/react-router/discussions/8753
!-  const history = props.history;
*+  const navigate = useNavigate(); 

!-   history.push("/elsewhere");
*+   navigate("/elsewhere");

!-   history.replace("/elsewhere");
*+   navigate("/elsewhere", { replace: true });

!-   history.go(-1);
*+   navigate(-1);
*/
function App() {
    return (
        <GeolocationContextProvider>
            <AuthContextProvider>
                <Switch>
                    <PublicRoute path={PATHS.SIGN_IN} exact>
                        <SignIn />
                    </PublicRoute>
                    <PublicRoute path={PATHS.SIGN_UP} exact>
                        <SignUp />
                    </PublicRoute>
                    <PublicRoute path={PATHS.FORGOT_PASSWORD} exact>
                        <ForgotPassword />
                    </PublicRoute>
                    <PublicRoute path={PATHS.RESET_PASSWORD} exact>
                        <ResetPassword />
                    </PublicRoute>
                    <CompatRoute path={PATHS.VERIFY_EMAIL} exact>
                        <VerifyEmail />
                    </CompatRoute>
                    <PrivateRoute path={PATHS.UPDATE_ACCOUNT_INFO} exact>
                        <UpdateAccountInfo />
                    </PrivateRoute>

                    {/* <PrivateRoute path={"/import-products"} exact>
                        <ProductsImporter />
                    </PrivateRoute> */}

                    {/* Subscription */}
                    <PrivateRoute path={PATHS.SUBSCRIPTIONS} exact>
                        <Subscriptions />
                    </PrivateRoute>

                    {/* Help */}
                    <LagacyRoute path={PATHS.HELP} exact>
                        <Guide />
                    </LagacyRoute>

                    {/* Dashboard */}
                    <PrivateRoute path={PATHS.DASHBOARD} exact>
                        <Dashboard />
                    </PrivateRoute>
                    {/* Services */}
                    <PrivateRoute path={PATHS.INSOURCE_PRODUCT_MERCHANDISING_PROJECTS} exact>
                        <InsourceProductMerchandisingProjects />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.ADD_INSOURCE_PRODUCT_MERCHANDISING_PROJECT} exact>
                        <AddInsourceProductMerchandisingProject />
                    </PrivateRoute>

                    <PrivateRoute path={PATHS.INSOURCE_PROJECTS} exact>
                        <InsourceProductMerchandisingProjects />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.ADD_INSOURCE_PROJECT} exact>
                        <AddInsourceProductMerchandisingProject />
                    </PrivateRoute>

                    {/* <PrivateRoute path={PATHS.PROMOTING_PROJECTS} exact>
                        <PromoterProjects />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.ADD_PROMOTING_PROJECT} exact>
                        <AddPromotingProject />
                    </PrivateRoute>
                    <PrivateRoute path={`${PATHS.MANAGE_PROMOTING_PROJECT}/:id/:tab`} exact>
                        <ManagePromotingProject />
                    </PrivateRoute> */}

                    {/* <LagacyRoute path={PATHS.INSOURCE_PROJECTS} exact>
                        <InsourceProjects />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.ADD_INSOURCE_PROJECT} exact>
                        <AddInsourceProject />
                    </LagacyRoute> */}
                    <LagacyRoute path={PATHS.MANAGE_INSOURCE_PROJECT} exact>
                        <ManageInsourceProject />
                    </LagacyRoute>
                    <PrivateRoute path={PATHS.ROUTE_PLAN_MANAGEMENT} exact>
                        <RoutePlanTableManagement />
                    </PrivateRoute>

                    <LagacyRoute path={PATHS.OUTSOURCE_PROJECTS} exact>
                        <OutsourceProjects />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.ADD_OUTSOURCE_PROJECT} exact>
                        <AddOutsourceProject />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.MANAGE_OUTSOURCE_PROJECT} exact>
                        <ManageOutsourceProject />
                    </LagacyRoute>

                    {/* Operations */}
                    <LagacyRoute path={PATHS.INSOURCE_VISITS_MANAGEMENT} exact>
                        <InsourceVisitsManagement />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.INSOURCE_VISIT_DETAILS} exact>
                        <InsourceVisitDetails />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.INSOURCE_VISIT_REPORT} exact>
                        <InsourceVisitReport />
                    </LagacyRoute>
                    <PrivateRoute path={PATHS.GALLERY} exact>
                        <Gallery />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.VISITS_POOL} exact>
                        <VisitsPool />
                    </PrivateRoute>

                    {/* Reports */}
                    <PrivateRoute path={PATHS.DAILY_MERCHANDISING_REPORT} exact>
                        <DailyMerchandisingReport />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.MERCHANDISER_ATTENDANCE_REPORT} exact>
                        <MerchandiserAttendanceReport />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.EXPIRY_AND_DAMAGE_REPORT} exact>
                        <ExpiryAndDamageReport />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.NEAR_EXPIRY_REPORT} exact>
                        <NearExpiryReport />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.OUT_OF_STOCK_REPORT} exact>
                        <OutOfStockReport />
                    </PrivateRoute>

                    <LagacyRoute path={PATHS.ROUTE_VISITS_MANAGEMENT} exact>
                        <RouteVisitsManagement />
                    </LagacyRoute>

                    <LagacyRoute path={PATHS.OUTSOURCE_VISITS_MANAGEMENT} exact>
                        <OutsourceVisitsManagement />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.OUTSOURCE_VISIT_DETAILS} exact>
                        <OutsourceVisitDetails />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.OUTSOURCE_VISIT_REPORT} exact>
                        <OutsourceVisitReport />
                    </LagacyRoute>
                    {/* Account */}
                    <LagacyRoute path={PATHS.ACCOUNT_INFO} exact>
                        <AccountInfo />
                    </LagacyRoute>
                    {/* Branches */}
                    <LagacyRoute path={PATHS.BRANCHES} exact>
                        <BranchesList />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.ADD_BRANCH} exact>
                        <AddBranch />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.UPDATE_BRANCH} exact>
                        <UpdateBranch />
                    </LagacyRoute>

                    {/* users management */}
                    <PrivateRoute path={`${PATHS.USERS}`} exact>
                        <Users />
                    </PrivateRoute>
                    {/* Employees */}
                    <LagacyRoute path={PATHS.EMPLOYEES} exact>
                        <EmployeesList />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.ADD_EMPLOYEE} exact>
                        <AddEmployee />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.UPDATE_EMPLOYEE} exact>
                        <UpdateEmployee />
                    </LagacyRoute>
                    {/* Products */}
                    <PrivateRoute path={PATHS.BRANDS} exact>
                        <Brands />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.COMPETITOR_BRANDS} exact>
                        <CompetitorBrands />
                    </PrivateRoute>
                    {/* <LagacyRoute path={PATHS.PRODUCTS} exact>
                        <ProductsList />
                    </LagacyRoute> */}
                    <PrivateRoute path={PATHS.PRODUCTS} exact>
                        <Products />
                    </PrivateRoute>
                    {/* <LagacyRoute path={PATHS.ADD_PRODUCT} exact>
                        <AddProduct />
                    </LagacyRoute> */}
                    <PrivateRoute path={PATHS.ADD_PRODUCT} exact>
                        <AddProduct />
                    </PrivateRoute>
                    <PrivateRoute path={`${PATHS.UPDATE_PRODUCT}/:id`} exact>
                        <UpdateProduct />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.ADD_COMPETITOR_PRODUCT} exact>
                        <AddCompetitorProduct />
                    </PrivateRoute>
                    <PrivateRoute path={`${PATHS.UPDATE_COMPETITOR_PRODUCT}/:id`} exact>
                        <UpdateCompetitorProduct />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.COMPETITOR_PRODUCTS} exact>
                        <CompetitorProducts />
                    </PrivateRoute>

                    <PrivateRoute path={PATHS.PROMOTION_PRICES} exact>
                        <PromotionPrices />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.ADD_PROMOTION_PRICES} exact>
                        <AddPromotionPrices />
                    </PrivateRoute>
                    <PrivateRoute path={`${PATHS.UPDATE_PROMOTION_PRICES}/:id`} exact>
                        <UpdatePromotionPrices />
                    </PrivateRoute>
                    {/* <LagacyRoute path={PATHS.UPDATE_PRODUCT} exact>
                        <UpdateProduct />
                    </LagacyRoute> */}
                    {/* Extra Displays */}
                    <LagacyRoute path={PATHS.EXTRA_DISPLAY_CONTRACTS} exact>
                        <ExtraDisplayContracts />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.ADD_EXTRA_DISPLAY_CONTRACT} exact>
                        <AddExtraDisplayContract />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.UPDATE_EXTRA_DISPLAY_CONTRACT} exact>
                        <UpdateExtraDisplayContract />
                    </LagacyRoute>

                    {/* Outlets */}
                    <PrivateRoute path={PATHS.LINK_OUTLETS} exact>
                        <LinkOutlets />
                    </PrivateRoute>
                    {/* <PrivateRoute path={PATHS.LINK_OUTLET_BRANCHES} exact>
                        <LinkOutletBranches />
                    </PrivateRoute> */}

                    {/* <LagacyRoute path={PATHS.LINK_OUTLETS} exact>
                        <SelectOutlets />
                    </LagacyRoute> */}
                    <PrivateRoute path={PATHS.LINK_OUTLET_BRANCHES} exact>
                        <SelectOutletBranches />
                    </PrivateRoute>
                    {/* <LagacyRoute path={PATHS.LINKED_OUTLETS} exact>
                        <LinkedOutlets />
                    </LagacyRoute>
                    <LagacyRoute path={PATHS.LINK_OUTLET_WITH_PRODUCTS} exact>
                        <LinkOutletWithProducts />
                    </LagacyRoute> */}
                    <PrivateRoute path={PATHS.LINK_PRODUCTS_WITH_OUTLETS} exact>
                        <LinkedProductsWithOutlets />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.LINK_PRODUCTS_WITH_OUTLET_BRANCHES} exact>
                        <LinkedProductsWithOutletBranches />
                    </PrivateRoute>

                    <PrivateRoute path={PATHS.EXCLUSIVITY_CONTRACTS} exact>
                        <ExclusivityContracts />
                    </PrivateRoute>
                    <PrivateRoute path={PATHS.UPDATE_EXCLUSIVITY_CONTRACT} exact>
                        <UpdateExclusivityContract />
                    </PrivateRoute>

                    <PublicRoute path="/mobile-dashboard/:id" exact>
                        <MobileDashboard />
                    </PublicRoute>

                    <PublicRoute path="/" exact>
                        <SignIn />
                    </PublicRoute>
                    <Route path="*">
                        <span>404: No Page Was Found.</span>
                    </Route>
                </Switch>
            </AuthContextProvider>
        </GeolocationContextProvider>
    );
}

export default App;
